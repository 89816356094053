<template>
  <div class="card" v-if="canAccess('rp_modulo_maestro')">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
    <Toolbar class="p-col-12">
      <template #right>
        <Button label="Exportar" icon="pi pi-file-excel" class="p-button-secondary p-mr-2"
                @click="exportReport('XLS')"/>
        <Button label="Imprimir" icon="pi pi-file-pdf" class="p-button-info p-mr-2"
                @click="exportReport('PDF')"/>
      </template>
      <template #left>
        <h6>Consolidado de personal activo</h6>
      </template>
    </Toolbar>
    <DataTable :value="items" rowGroupMode="rowspan" groupRowsBy="position"
               sortMode="single" sortField="position" :sortOrder="1">
      <Column field="position" header="Cargo">
        <template #body="slotProps">
          <span class="image-text">{{ slotProps.data.position }}</span>
        </template>
      </Column>
      <Column field="country" header="País">
        <template #body="slotProps">
          <span class="image-text">{{ slotProps.data.country }}</span>
        </template>
      </Column>
      <Column field="count" header="Cantidad"></Column>
      <ColumnGroup type="footer">
        <Row>
          <Column footer="Total:" :colspan="2" footerStyle="text-align:right"/>
          <Column :footer="total"/>
        </Row>
      </ColumnGroup>
    </DataTable>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>

</template>

<script>
import bouncer from "../../../helpers/bouncer";
import service from '../service/report.service'
import {mapGetters} from "vuex";

export default {
  mixins: [bouncer],
  name: "CoreReport",
  data() {
    return {
      service: service('positions'),
      items: [],
      total: 0,
      loading: false,
    }
  },
  mounted() {
    if (this.canAccess('rp_modulo_maestro')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Estadísticas', url: "javascript:void(0);"}]);
      this.loading = true;
      this.items = [];
      this.service.getPositions().then(x => {
        this.total = x.data.totals?.positions;
        const positions = x.data.positions;
        positions.forEach(p => {
          p.countries = p.countries.map(c => {
            return {...c, position: `${p.name}`};
          });
          this.items = this.items.concat(p.countries)
        })
      }).catch(err => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => this.loading = false)
    }
  },
  methods: {
    exportReport(command) {
      this.loading = true;
      this.service.getPositions({export: command}).then(x => {
        window.open(x.data.url_file)
      }).finally(() => this.loading = false)

    }
  },
  computed: {
    ...mapGetters('bread', ['breadcrumb']),
  }
}
</script>

<style scoped>

</style>
